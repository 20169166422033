export const useModelDefault = () => {
  const attendeeDefault = {
    name: '',
    namebadge: '',
    email: '',
    birthdate: '',
    franchisee: '',
  };

  const cctokenDefault = {
    number: '',
    name: '',
    expireAt: '',
    code: '',
    email: '',
    installments: 1,
    country: '',
  };

  const customerDefault = {
    name: '',
    email: '',
    doyouagree: false,
    document: '',
    mobile: '',
    address: '',
    city: '',
    state: '',
  };

  const billingDefault = {
    number: '',
    address: '',
    neighborhood: '',
    city: '',
    state: '',
    zipCode: '',
  };

  const transactionDefault = {
    promoCode: 'NOCODE',
    eventId: process.env.REACT_APP_EVENT_ID,
    eventCode: parseInt(process.env.REACT_APP_EVENT_ID_MICE),
    attendees: [],
    products: [],
    paymentType: '',
    customer: null,
    billing: null,
    token: '',
    installments: 1,
    login: null,
  };

  const loginDefault = {
    langugage: null,
    country: '',
    email: '',
    domain: '',
  };

  return {
    attendeeDefault,
    cctokenDefault,
    customerDefault,
    billingDefault,
    transactionDefault,
    loginDefault,
  };
};
