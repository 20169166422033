import axios from 'axios';
import * as Sentry from '@sentry/react';

const isApproved = async (email) => {
  const config = {
    method: 'GET',
    url: `${process.env.REACT_APP_BASE_URL}/kumon/2025/events/${process.env.REACT_APP_EVENT_ID}/franchisees/${email}`,
    headers: {
      'content-type': 'application/json',
    },
  };

  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((response) => resolve(response.data))
      .catch((error) => {
        const exception = error?.response?.data?.success === false;
        if (!exception) {
          Sentry.setContext('request', { config });
          Sentry.setContext('response', error.response.data);
          Sentry.captureMessage(config.url);
        }
        resolve({
          success: false,
          message: !exception ? 'generic' : error?.response?.data?.message,
        });
      });
  });
};

const isPaid = async (id) => {
  const config = {
    method: 'GET',
    url: `${process.env.REACT_APP_BASE_URL}/transactions/${id}`,
    headers: {
      'content-type': 'application/json',
    },
  };

  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((response) => resolve(response.data))
      .catch((error) => {
        const notpaid = error?.response?.data?.success === false;
        if (!notpaid) {
          Sentry.setContext('request', { config });
          Sentry.setContext('response', error.response.data);
          Sentry.captureMessage(config.url);
        }
        resolve({ success: false, data: error });
      });
  });
};

const transactions = async (data) => {
  const config = {
    method: 'POST',
    url: `${process.env.REACT_APP_BASE_URL}/kumon/2025/transactions`,
    headers: {
      'content-type': 'application/json',
    },
    data,
  };
  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((response) => resolve({ success: true, data: response.data }))
      .catch((error) => {
        Sentry.setContext('request', { config });
        Sentry.setContext('response', error.response.data);
        Sentry.captureMessage(config.url);
        resolve({ success: false, data: error.response.data });
      });
  });
};

const isBlockedByAdBlock = () => {
  return new Promise(async (resolve) => {
    try {
      window.Iugu.utils.isBlockedByAdBlock((response) => {
        resolve(response);
      });
    } catch (error) {
      resolve(false);
    }
  });
};

const emailValidation = async (data) => {
  const config = {
    method: 'POST',
    url: `${process.env.REACT_APP_BASE_URL}/kumon/2025/validation`,
    headers: {
      'content-type': 'application/json',
    },
    data,
  };

  try {
    const { data } = await axios(config);
    return data;
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export {
  transactions,
  isPaid,
  isBlockedByAdBlock,
  isApproved,
  emailValidation,
};
